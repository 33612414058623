import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CTA from "../components/CTA/cta";

// This route exists to correct any issues with redirects.

const Unsub = () => {
	useEffect(() => {
		window.location.href = "https://au.tearribles.com";
	}, []);

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<p
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "88px 2rem 2rem",
				}}
			>
				You have been unsubscribed. You will no longer receive updates
				from Tearribles.
				<br />
				<br />
				<CTA title="Go to Home Page" to="/" />
			</p>
		</>
	);
};

export default Unsub;
